import React, { useMemo, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import useFetch from "../../../hooks/useFetch"
import { Link } from "gatsby"
import { Col, Row } from "reactstrap"
import RadioButton from "../../../templates/EditSpace/fields/_Radio_Button"
import ToggleSwitch from "../../../templates/EditSpace/fields/ToggleSwitch"
import { useAuth } from "../../../auth/useAuth"
import { toast } from "react-toastify"

const SpaceOperators = () => {
  const { progress, data } = useFetch("members", "get")

  const [search, setSearch] = useState("")

  const [onlyConnectDisabled, setOnlyConnectDisabled] = useState(false)

  const filteredUsers = useMemo(() => {
    if (!data?.users) return []
    return data?.users?.filter((u) => {
      if (search && search?.length < 3) return true
      if (!search && !onlyConnectDisabled) return true
      if (search && !u?.displayName) return false

      const validConnect = onlyConnectDisabled ? !u?.connectEnabled : true

      const matchingName = u?.displayName
        ?.toLowerCase()
        ?.includes(search.toLowerCase())
      const matchingEmail = u?.email
        ?.toLowerCase()
        ?.includes(search.toLowerCase())

      const matchingDetails = matchingName || matchingEmail
      return matchingDetails && validConnect
    })
  }, [data?.users, search, onlyConnectDisabled])

  return (
    <>
      <SEO title="Members" />
      <Layout title={"Members"} progress={progress}>
        <Row>
          <Col xl={6}>
            <input
              type="text"
              placeholder={"Type name to search"}
              className="form-control mb-4"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>

        <div className={"d-flex align-items-center mb-4"}>
          <span className={"mr-4"}>Show only the members without connect?</span>
          <RadioButton
            name={"onlyConnectEnabled"}
            value={onlyConnectDisabled}
            onChange={() => setOnlyConnectDisabled(!onlyConnectDisabled)}
          />
        </div>

        <p>
          Displaying {filteredUsers?.length} of {data?.users?.length}
          users
        </p>
        {filteredUsers?.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th className={"text-center"}>Hierarchy Score</th>
                <th className={"text-center"}>Features?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers
                .sort((b, a) => a.hierarchyScore - b.hierarchyScore)
                .map((user) => (
                  <Member key={user.id} user={user} />
                ))}
            </tbody>
          </table>
        )}
      </Layout>
    </>
  )
}

export default SpaceOperators

const Member = ({ user }) => {
  const [value, setValue] = useState(user.isFeaturedProfile)
  const [loading, setLoading] = useState(false)

  const { makeRequest } = useAuth()

  const handleToggle = async (value) => {
    setValue(value)
    setLoading(true)

    await makeRequest("members", {
      id: user?.id,
      data: {
        isFeaturedProfile: value,
      },
    })

    toast.success("Updated")
    setLoading(false)
  }
  return (
    <tr key={user.id}>
      <td>
        <a
          href={`https://members.worksimply.com/${user.userSlug || user.id}`}
          target={"_blank"}
          className={user?.connectEnabled ? "text-dark" : "text-danger"}
        >
          {user?.displayName || user?.uid}{" "}
        </a>
      </td>
      <td>{user?.email}</td>
      <td className={"text-center"}>{user?.hierarchyScore}</td>
      <td className={"text-center"}>
        <ToggleSwitch value={value} onChange={handleToggle} />
      </td>
      <td className={"text-right"}>
        <Link to={`/admin/members/${user.id}`} className={"text-success"}>
          Edit
        </Link>
      </td>
    </tr>
  )
}
